import React from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from 'components';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

import SectionSeparator from 'components/Blog/section-separator';
import Avatar from 'components/avatar';
import Date from 'components/date';
import CoverImage from 'components/cover-image';
import PostTitle from 'components/post-title';
import BlogThumbnail from 'components/Blog/Thumbnail';
import { SEO } from 'components';

export default function Post({ data: { post, morePosts } }) {
  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={post?.seo?.description}
          image={post?.seo?.image}
          title={post?.seo?.title}
          seoKeywords={post?.seoKeywords}
        />
        <div className="container mx-auto">
          <BlogHeader
            title={post.title}
            coverImage={post.coverImage}
            date={post.date}
            author={post.author}
          />
          <article className="mb-3" style={{ padding: '0px 20px' }}>
            <div className="max-w-2xl mx-auto">
              <div className="mb-1 flex">
                <Link className="blog-thumb-link" to={`/blog`}>
                  All
                </Link>
                <span style={{ margin: '0px 5px 0px 5px' }}>|</span>
                {post?.categories?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Link className="blog-thumb-link" to={`/blog/categories/${item.slug}`}>
                      {item.name}
                    </Link>
                    {index < post.categories.length - 1 && (
                      <span style={{ margin: '0px 5px 0px 5px' }}>|</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <PostTitle>{post.title}</PostTitle>
              <div className="block md:hidden">
                <Avatar name={post.author?.name} picture={post.author?.picture} />
              </div>
              <div className="mb-2 text-lg">
                <Date dateString={post.date} />
              </div>
            </div>
            <MathJax hideUntilTypeset="first">
              {<div dangerouslySetInnerHTML={{ __html: post.content2 }} />}
            </MathJax>
          </article>
            {morePosts.nodes?.length > 0 && (
                 <>
                    <SectionSeparator />
                    <div>
                        <h2 className="mb-2 mt-3">More Posts</h2>
                        <div className="srow">
                        {morePosts.nodes?.map((post, index) => (
                            <BlogThumbnail key={index} post={post} />
                        ))}
                        </div>
                    </div>
                </>
            )}
        </div>
      </Layout>
    </MathJaxContext>
  );
}

function BlogHeader({ title, coverImage, author }) {
  return (
    <>
      {/* <div className="hidden md:block md:mb-12">
        <Avatar name={author?.name} picture={author?.picture} />
      </div> */}
      <div className="mb-2 md:mb-16 sm:mx-0">
        <CoverImage title={title} fluid={coverImage?.gatsbyImageData} />
      </div>
    </>
  );
}

export const query = graphql`
  query PostBySlug($id: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    post: datoCmsPost(id: { eq: $id }) {
      seo {
        description
        title
        image {
          url
        }
      }
      seoKeywords {
        keyword
      }
      title
      slug
      content2
      content {
        value
        blocks {
          __typename
          id: originalId
          image {
            gatsbyImageData(width: 700)
          }
        }
      }
      date
      categories: category2 {
        name
        originalId
        slug
      }
      coverImage {
        gatsbyImageData(width: 1200)
      }
      author {
        name
        picture {
          gatsbyImageData(layout: FIXED, width: 48, height: 48)
        }
      }
    }
    morePosts: allDatoCmsPost(
      sort: { fields: date, order: DESC }
      limit: 3
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          #   large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 760, height: 428)
        }
        category: category2 {
          name
          originalId
          slug
        }
        author {
          name
          picture {
            gatsbyImageData(layout: FIXED, width: 48, height: 48)
          }
        }
      }
    }
  }
`;
